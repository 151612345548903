import React from "react"
import * as styles from "../styles/projects.module.css"
import moment from "moment"
import { FaLock } from "@react-icons/all-files/fa/FaLock"

export default function RepoInfo({ repo }) {
  return (
    <div className={styles.repos}>
      <div>
        <a
          href={repo.url}
          target="_blank"
          rel="noreferrer"
          className={styles.name}
          title="View GitHub Repo"
        >
          {repo.name}
        </a>
        {repo.description && <p className="">{repo.description}</p>}
        {repo.languages && (
          <p className={styles.stats}>
            Languages:{" "}
            {repo.languages.nodes.map((lang, index) => {
              return (
                <>
                  {lang.name}
                  {index === repo.languages.nodes.length - 1 ? "" : ","}{" "}
                </>
              )
            })}
            <br />
            Created:{" "}
            {repo.createdAt && moment(repo.createdAt).format("MM/DD/YYYY")}
          </p>
        )}
      </div>
      <div className="">
        {repo.homepageUrl && (
          <>
            <a href={repo.homepageUrl}>
              <button className={styles.btn} title="View Site">
                <span className={styles.blink}>LIVE</span>
              </button>
            </a>
          </>
        )}
        {repo.isPrivate && (
          <button
            className={styles.btn}
            title="Private Project"
            style={{ marginTop: "2px" }}
          >
            <FaLock />
          </button>
        )}
      </div>
    </div>
  )
}
