import React from "react"
import * as styles from "../styles/projects.module.css"

const Search = ({ search, onSearchChange, count, onCountChange, total }) => {
  return (
    <div className={styles.projects}>
      <div className={styles.query_string}>
        <label htmlFor="queryString" className="">
          Search
        </label>
        <input
          id="queryString"
          type="text"
          placeholder="Search by name or tech"
          className={styles.query_input}
          value={search}
          onChange={e => onSearchChange(e.target.value)}
        />
      </div>
      <div className={styles.query_count}>
        <label htmlFor="pageCount" className="me-2 fw-bold text-secondary">
          Show
        </label>
        <input
          id="pageCount"
          className={styles.count}
          type="number"
          min="1"
          max="100"
          placeholder={count}
          onChange={e => onCountChange(e.target.value)}
        />
      </div>
      <div className={styles.total_repos}>
        <b>Total Repos: {total}</b>
      </div>
    </div>
  )
}

export default Search
