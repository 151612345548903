// extracted by mini-css-extract-plugin
export var blink = "projects-module--blink--ojSpo";
export var blinkAnimation = "projects-module--blink-animation--Sdgl9";
export var btn = "projects-module--btn--XuCvH";
export var count = "projects-module--count---2awR";
export var filtered_by = "projects-module--filtered_by--sEkq-";
export var name = "projects-module--name--7t3fB";
export var portfolio = "projects-module--portfolio--c8lid";
export var projects = "projects-module--projects--DmUc+";
export var query_count = "projects-module--query_count--BIAoO";
export var query_input = "projects-module--query_input--RkyFe";
export var query_string = "projects-module--query_string--Dodgy";
export var repos = "projects-module--repos--SJRDF";
export var stats = "projects-module--stats--KyocN";
export var total_repos = "projects-module--total_repos--KBeoA";