import React from "react"
import * as styles from "../styles/projects.module.css"

const RepoHeader = () => {
  return (
    <div className={styles.repos}>
      <h2>
        Repos <span className={styles.filtered_by}>(by last updated)</span>
      </h2>
      <h2>Status</h2>
    </div>
  )
}

export default RepoHeader
